<template>
    <section>
        <div class="container">
            <TitleBar title="Malzeme Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-3" label="ID">
                    <b-input v-model="materials.filters.id"></b-input>
                </b-field>
                <b-field class="column is-3" label="Malzeme Sınıfı">
                    <b-select placeholder="Seçiniz.." v-model="materials.filters.class" expanded>
                        <option value="Hammadde">Hammadde</option>
                        <option value="Yarı Mamül">Yarı Mamül</option>
                        <option value="Mamül">Mamül</option>
                        <option value="Ticari">Ticari</option>
                        <option value="Diğer">Diğer</option>
                    </b-select>
                </b-field>
                <b-field class="column is-3" label="Ana Grup">
                    <b-input v-model="materials.filters.maingroup"></b-input>
                </b-field>
                <b-field class="column is-3" label="Alt Grup">
                    <b-input v-model="materials.filters.subgroup"></b-input>
                </b-field>
                <b-field class="column is-3" label="Malzeme Kodu">
                    <b-input v-model="materials.filters.code"></b-input>
                </b-field>
                <b-field class="column is-9" label="Malzeme Açıklaması">
                    <b-input v-model="materials.filters.name"></b-input>
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="Malzeme Tablosu" grouped group-multiline>
                <b-field class="column is-12" grouped group-multiline>
                    <b-button class="column" type="is-info is-light" icon-left="magnify" label="Ara" @click="materials_read" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="plus" label="Yeni" @click="materials_new" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="content-copy" label="Ref. Al" @click="materials_ref" disabled/>
                    <b-button class="column ml-1" type="is-info is-light" icon-left="eye" label="Görüntüle" :disabled="!materials.selected.id" @click="materials_show" />
                    <b-button class="column ml-1" type="is-warning is-light" icon-left="pencil" label="Düzenle" :disabled="!materials.selected.id" @click="materials_edit" />
                    <b-button class="column ml-1" type="is-danger is-light" icon-left="delete" label="Sil" :disabled="!materials.selected.id" @click="materials_delete" />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="printer" label="Yazdır" @click="materials_print" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Içe Aktar" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" @click="materials_export" disabled />
                </b-field>

                <b-table
                    class="column is-12"
                    :mobile-cards="false"
                    :data="materials.data"
                    :total="materials.total"
                    :current-page.sync="materials.page"
                    :selected.sync="materials.selected"
                    :sort-multiple-data.sync="materials.sorts"
                    :loading="$apollo.queries.readMaterials.loading"
                    @dblclick="materials_show"
                    @sort="materials_sort"
                    @sorting-priority-removed="materials_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    narrowed
                    paginated
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">Malzeme kaydı bulunamadı.</div>
                    </template>

                    <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-4) }}</b-table-column>
                    <b-table-column field="class" label="Sınıf" v-slot="props" sortable>{{ props.row.class }}</b-table-column>
                    <b-table-column field="code" label="Malzeme Kodu" v-slot="props" sortable>{{ props.row.code }}</b-table-column>
                    <b-table-column field="name" label="Malzeme Açıklaması" v-slot="props" sortable>{{ props.row.name }}</b-table-column>
                    <b-table-column field="maingroup" label="Ana Grup" v-slot="props" sortable>{{ props.row.maingroup }}</b-table-column>
                    <b-table-column field="subgroup" label="Alt Grup" v-slot="props" sortable>{{ props.row.subgroup }}</b-table-column>
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "MaterialTable",
    components: {
        TitleBar,
    },
    data: () => ({
        modi: "",
        materials: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {},
            selected: {},
        },
    }),
   mounted(){
        this.materials_read();
    },
    methods: {
        materials_read() {
            this.$apollo.queries.readMaterials.start();
            this.$apollo.queries.readMaterials.refetch();
        },
        materials_new() {
            this.$router.push({ path: "/malzeme/ekle" });
        },
        materials_ref() {
            this.$router.push({
                path: "/malzeme/ekle",
                query: { id: this.materials.selected.id, modi: "ref" },
            });
        },
        materials_show() {
            this.$router.push({
                path: "/malzeme/detay",
                query: { id: this.materials.selected.id, modi: "show" },
            });
        },
        materials_edit() {
            this.$router.push({
                path: "/malzeme/detay",
                query: { id: this.materials.selected.id, modi: "edit" },
            });
        },
        materials_delete() {},
        materials_print() {},
        materials_export() {},
        materials_sort(field, order) {
            this.materials_nosort(field);
            this.materials.sorts.push({ field, order });
        },
        materials_nosort(field) {
            this.materials.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        },
    },
    apollo: {
        readMaterials: {
            query: gql`
                query readMaterials($config: String) {
                    readMaterials(config: $config) {
                        id
                        code
                        name
                        class
                        maingroup
                        subgroup
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.materials.sorts.forEach((e) => sorts.push({ column: e.field, order: e.order }));
                for (var filter in this.materials.filters) if (!this.materials.filters[filter]) delete this.materials.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.materials.take,
                        filters: this.materials.filters,
                        skip: (this.materials.page - 1) * this.materials.take,
                    }),
                };
            },
            update(data) {
                this.materials.data = data.readMaterials;
            },
        },
    },
};
</script>
